import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    // NormalizedCacheObject,
} from '@apollo/client';

import BigNumber from 'bignumber.js';

import { gql } from 'graphql-tag';

import { useEffect, useState } from 'react';
import { useAppSelector } from 'state/hooks';
import { Env } from '../constants';

const L2_WETH_ADDRESS = '0x420000000000000000000000000000000000000a';

export interface Token {
    id: string;
    symbol: string;
    name: string;
    decimals: string;
}

export interface l1SubgraphUser {
    id: string;
    transferOuts: l1SubgraphTransferOut[];
    transferIns: l1SubgraphTransferIn[];

    transferOutsCount: string;
    transferInsCount: string;
}

export interface l1SubgraphTransferOut {
    id: string;

    isTrasferETH: boolean;
    l1Token?: Token;
    l2Token?: string;

    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: string;
}

export interface l1SubgraphTransferIn {
    id: string;

    isTrasferETH: boolean;
    l1Token?: Token;
    l2Token?: string;

    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: string;
}

export interface l2SubgraphUser {
    id: string;
    transferOuts: l2SubgraphTransferOut[];
    transferIns: l2SubgraphTransferIn[];

    transferOutsCount: string;
    transferInsCount: string;
}

export interface l2SubgraphTransferOut {
    id: string;

    l1Token?: string;
    l2Token?: Token;

    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: string;
}

export interface l2SubgraphTransferIn {
    id: string;

    l2Token?: Token;
    l1Token?: string;

    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: string;
}

export enum RecordStatus {
    pending = 0,
    finished = 1,
}

interface DepositRecord {
    // tx hash
    id: string;

    isTrasferETH: boolean;
    l1Token?: Token;
    l2Token?: string;

    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: string;

    status: RecordStatus;

    finishTimestamp?: string;
    finishBlockNumber?: string;
}

interface WithdrawRecord {
    id: string;

    l1Token?: string;
    l2Token?: Token;

    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: string;

    status: RecordStatus;

    finishTimestamp?: string;
    finishBlockNumber?: string;
}

interface Record {
    id: string;
    token: string;
    symbol: string;
    isTrasferETH?: boolean;
    from: string;
    to: string;
    amount: string;

    blockNumber: string;
    timestamp: number;

    finishTimestamp?: number;
    finishBlockNumber?: string;

    status: RecordStatus;
    isDeposit: boolean;
}

const L1Client = {
    [Env.mainnet]:
        'https://faas-sgp1-18bc02ac.doserverless.co/api/v1/web/fn-2083676f-cd29-43ec-902e-92c16d753dfe/default/l1-bridge-subgraph',
    [Env.test]:
        'https://api.thegraph.com/subgraphs/id/QmXcbcwcMACcZCRSUoz4wuqT51FjQzGDzVb5Qd36d4asRh',
};

const L2Client = {
    [Env.mainnet]:
        'https://metisapi.0xgraph.xyz/subgraphs/name/metis/l2-bridge',
    [Env.test]:
        'https://goerli.thegraph.metis.io/subgraphs/name/metis/l2-bridge',
};

export default function useGetBridgeHistoryRecords() {
    const [l1SubgraphClient, setL1SubgraphClient] =
        useState<ApolloClient<NormalizedCacheObject>>();
    const [l2SubgraphClient, setL2SubgraphClient] =
        useState<ApolloClient<NormalizedCacheObject>>();
    const { address } = useAppSelector((state) => state.user);
    const { env } = useAppSelector((state) => state.metisMiddlewareClient);
    const [records, setRecords] = useState<Record[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const l1Client = new ApolloClient({
            link: createHttpLink({
                // uri: "https://api.thegraph.com/subgraphs/name/billlightman/l1-bridge",
                uri: L1Client[env],
            }),
            cache: new InMemoryCache(),
        });

        setL1SubgraphClient(l1Client);

        const l2Client = new ApolloClient({
            link: createHttpLink({
                uri: L2Client[env],
            }),
            cache: new InMemoryCache(),
        });

        setL2SubgraphClient(l2Client);
        setRecords([]);
        setLoading(true);
    }, [env]);

    async function getUserLastestDepositRecords(
        address: string,
    ): Promise<DepositRecord[]> {
        if (!l1SubgraphClient || !l2SubgraphClient) return [];
        // l1的钱包地址
        const userAddress = address.toLowerCase();
        const records: DepositRecord[] = [];

        const queryDepositRecords = await l1SubgraphClient.query<{
            user?: l1SubgraphUser;
        }>({
            query: gql`
                query ($user: Bytes!) {
                    user(id: $user) {
                        id
                        transferOutsCount

                        transferOuts(
                            first: 100
                            orderBy: timestamp
                            orderDirection: desc
                        ) {
                            id
                            isTrasferETH
                            l1Token {
                                id
                                name
                                symbol
                                decimals
                            }
                            l2Token
                            from
                            to
                            amount
                            blockNumber
                            timestamp
                        }
                    }
                }
            `,
            variables: {
                user: userAddress,
            },
            fetchPolicy: 'no-cache',
        });

        const userData = queryDepositRecords.data.user;

        if (!userData) {
            return records;
        }

        const totalDepositRecordsCount: BigNumber = new BigNumber(
            userData.transferOutsCount,
        );

        const recordSkipIndex = Number(
            totalDepositRecordsCount.minus(userData.transferOuts.length),
        );

        const initialRecords = userData.transferOuts;

        const queryDepositFinishedRecords = await l2SubgraphClient.query<{
            user?: l2SubgraphUser;
        }>({
            query: gql`
                query ($user: Bytes!, $skip: Int!) {
                    user(id: $user) {
                        id
                        transferInsCount

                        transferIns(
                            skip: $skip
                            first: 100
                            orderBy: blockNumber
                            orderDirection: asc
                        ) {
                            id
                            l1Token
                            l2Token {
                                id
                                name
                                symbol
                                decimals
                            }
                            from
                            to
                            amount
                            blockNumber
                            timestamp
                        }
                    }
                }
            `,
            variables: {
                user: userAddress,
                skip: recordSkipIndex,
            },
            fetchPolicy: 'no-cache',
        });

        const finishedRecords = queryDepositFinishedRecords.data.user
            ? queryDepositFinishedRecords.data.user.transferIns
            : [];

        for (const index in initialRecords) {
            const initialRecord = initialRecords[index];
            const newRecord: DepositRecord = {
                id: initialRecord.id,

                isTrasferETH: initialRecord.isTrasferETH,
                l1Token: initialRecord.l1Token,
                l2Token: initialRecord.l2Token,

                from: initialRecord.from,
                to: initialRecord.to,
                amount: initialRecord.amount,

                blockNumber: initialRecord.blockNumber,
                timestamp: initialRecord.timestamp,

                status: RecordStatus.pending,
            };

            const finishRecordIndex = initialRecords.length - Number(index) - 1;

            const finishedRecord = finishedRecords[finishRecordIndex];
            if (finishedRecord) {
                // if (
                //     finishedRecord.from === initialRecord.from &&
                //     new BigNumber(finishedRecord.amount)
                //         .minus(initialRecord.amount)
                //         .abs()
                //         .lt(0.000001)
                // ) {
                //     newRecord.status = RecordStatus.finished;
                // } else {
                //     console.log(index, initialRecord, finishedRecord);
                // }
                newRecord.status = RecordStatus.finished;
                newRecord.finishTimestamp = finishedRecord.timestamp;
                newRecord.finishBlockNumber = finishedRecord.blockNumber;
            }

            records.push(newRecord);
        }

        return records;
    }

    async function getUserLastestWithdrawRecords(address: string) {
        // l2的钱包地址
        if (!l2SubgraphClient || !l1SubgraphClient) return [];
        const userAddress = address.toLowerCase();
        const records: WithdrawRecord[] = [];

        const totalWithdrawInitialRecords: l2SubgraphTransferOut[] = [];
        let totalWithdrawInitialRecordsCount = -1;
        let initialRecordSkip = 0;
        const totalWithdrawFinishRecords: l1SubgraphTransferIn[] = [];
        let totalWithdrawFinishRecordsCount = -1;
        let finishedRecordSkip = 0;

        while (
            initialRecordSkip < totalWithdrawInitialRecordsCount ||
            totalWithdrawInitialRecordsCount === -1
        ) {
            const queryWithdrawRecords = await l2SubgraphClient.query<{
                user?: l2SubgraphUser;
            }>({
                query: gql`
                    query ($user: Bytes!, $skip: Int!) {
                        user(id: $user) {
                            id
                            transferOutsCount

                            transferOuts(
                                skip: $skip
                                first: 100
                                orderBy: blockNumber
                                orderDirection: asc
                            ) {
                                id
                                l2Token {
                                    id
                                    name
                                    symbol
                                    decimals
                                }
                                l1Token
                                from
                                to
                                amount
                                blockNumber
                                timestamp
                            }
                        }
                    }
                `,
                variables: {
                    user: userAddress,
                    skip: initialRecordSkip,
                },
                fetchPolicy: 'no-cache',
            });

            const userData = queryWithdrawRecords.data.user;

            if (!userData) {
                return records;
            }

            totalWithdrawInitialRecordsCount = Number(
                userData.transferOutsCount,
            );

            if (totalWithdrawInitialRecordsCount > 10000) {
                throw new Error('too large withdraw records');
            }

            totalWithdrawInitialRecords.push(...userData.transferOuts);
            initialRecordSkip += 100;
        }

        while (
            finishedRecordSkip < totalWithdrawFinishRecordsCount ||
            totalWithdrawFinishRecordsCount === -1
        ) {
            const queryWithdrawFinishedRecords = await l1SubgraphClient.query<{
                user?: l1SubgraphUser;
            }>({
                query: gql`
                    query ($user: Bytes!, $skip: Int!) {
                        user(id: $user) {
                            id
                            transferInsCount

                            transferIns(
                                skip: $skip
                                first: 100
                                orderBy: timestamp
                                orderDirection: asc
                            ) {
                                id
                                isTrasferETH
                                l2Token
                                l1Token {
                                    id
                                    name
                                    symbol
                                    decimals
                                }
                                from
                                to
                                amount
                                blockNumber
                                timestamp
                            }
                        }
                    }
                `,
                variables: {
                    user: userAddress,
                    skip: finishedRecordSkip,
                },
                fetchPolicy: 'no-cache',
            });

            if (!queryWithdrawFinishedRecords.data.user) {
                break;
            }

            totalWithdrawFinishRecordsCount = Number(
                queryWithdrawFinishedRecords.data.user.transferInsCount,
            );
            totalWithdrawFinishRecords.push(
                ...queryWithdrawFinishedRecords.data.user.transferIns,
            );
            finishedRecordSkip += 100;
        }

        // {`${token address}-${amount}`: count number}
        const statL1Records: { [key: string]: l1SubgraphTransferIn[] } = {};
        for (const finishRecord of totalWithdrawFinishRecords) {
            const key = `${
                finishRecord.isTrasferETH
                    ? L2_WETH_ADDRESS.toLowerCase()
                    : finishRecord.l2Token
            }-${finishRecord.amount}`;
            statL1Records[key] = statL1Records[key]
                ? [...statL1Records[key], finishRecord]
                : [finishRecord];
        }

        for (const initialRecord of totalWithdrawInitialRecords) {
            const newRecord: WithdrawRecord = {
                id: initialRecord.id,
                l1Token: initialRecord.l1Token,
                l2Token: initialRecord.l2Token,
                amount: initialRecord.amount,

                blockNumber: initialRecord.blockNumber,
                timestamp: initialRecord.timestamp,
                status: RecordStatus.pending,
                from: initialRecord.from,
                to: initialRecord.to,
            };

            const key = `${
                initialRecord.l2Token ? initialRecord.l2Token.id : ''
            }-${initialRecord.amount}`;
            if (statL1Records[key] && statL1Records[key].length > 0) {
                const finishRecord: any = statL1Records[key].shift();
                newRecord.status = RecordStatus.finished;
                newRecord.finishBlockNumber = finishRecord.blockNumber;
                newRecord.finishTimestamp = finishRecord.timestamp;
            }

            records.push(newRecord);
        }

        return records;
    }

    async function getRecords(address: string) {
        try {
            setLoading(true);
            const l1DepositRecords = await getUserLastestDepositRecords(
                address,
            );
            const l2WithdrawRecords = await getUserLastestWithdrawRecords(
                address,
            );

            let records: Record[] = [];

            for (let i = 0, len = l1DepositRecords.length; i < len; i++) {
                const item = l1DepositRecords[i];
                records.push({
                    id: item.id,

                    isTrasferETH: item.isTrasferETH,
                    token: item.l1Token ? item.l1Token.id : '',
                    symbol: item.l1Token
                        ? item.l1Token.symbol.toUpperCase()
                        : 'ETH',

                    from: item.from,
                    to: item.to,
                    amount: item.amount,
                    blockNumber: item.blockNumber,
                    timestamp: Number(item.timestamp) * 1000,

                    status: item.status,
                    isDeposit: true,

                    finishTimestamp: item.finishTimestamp
                        ? Number(item.finishTimestamp) * 1000
                        : undefined,
                    finishBlockNumber: item.finishBlockNumber,
                });
            }

            for (let i = 0, len = l2WithdrawRecords.length; i < len; i++) {
                const item = l2WithdrawRecords[i];
                records.push({
                    id: item.id,

                    isTrasferETH: false,
                    token: item.l2Token ? item.l2Token.id : '',
                    symbol: item.l2Token
                        ? item.l2Token.symbol.toUpperCase()
                        : '',

                    from: item.from,
                    to: item.to,
                    amount: item.amount,
                    blockNumber: item.blockNumber,
                    timestamp: Number(item.timestamp) * 1000,

                    status: item.status,
                    isDeposit: false,

                    finishTimestamp: item.finishTimestamp
                        ? Number(item.finishTimestamp) * 1000
                        : undefined,
                    finishBlockNumber: item.finishBlockNumber,
                });
            }

            records = records.sort((a, b) => {
                return b.timestamp - a.timestamp;
            });
            setRecords(records);
            setLoading(false);
        } catch (e) {
            console.error('get bridge records error');
            console.error(e);
        }
    }

    useEffect(() => {
        if (l1SubgraphClient && l2SubgraphClient && address) {
            getRecords(address);
        }
    }, [l1SubgraphClient, l2SubgraphClient, address]);

    return {
        records,
        getRecords,
        loading,
    };
}
